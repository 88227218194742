import React from 'react';
import { HashRouter as Router, Route } from "react-router-dom";
import { Provider } from 'react-redux';

import './shared/amplify';
import 'bootstrap/dist/css/bootstrap.min.css';

import store from './store';

// Pages
import HomePage from './pages/Home';
import SignInPage from './pages/SignIn';
import SignUpPage from './pages/SignUp';
//import InvitationPage from './pages/Invitation';
import InvitePage from './pages/Invite';
import OAuthPage from './pages/OAuth';
import DisconnectPage from './pages/Disconnect';

store.dispatch({ type: 'INITIALIZE' });

if (process.env.NODE_ENV === 'development') {
  window.store = store;
}

function App () {
  return (
    <Provider store={store}>
      <Router>
        <Route path="/" exact component={HomePage} />
        <Route path="/sign-in" exact component={SignInPage} />
        <Route path="/sign-up" exact component={SignUpPage} />
        {/*<Route path="/invitation/:id" exact component={InvitationPage} />*/}
        <Route path="/invite/:id" exact component={InvitePage} />
        <Route path="/oauth" exact component={OAuthPage} />
        <Route path="/disconnect" exact component={DisconnectPage} />
      </Router>
    </Provider>
  )
}

export default App;