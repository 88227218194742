import React, { useEffect, useState } from 'react';
import UserCard2 from '../UserCard2';
import axios from 'axios';

/**
 * Load invite data
 * @param {String} email 
 * @param {Object} api 
 */
async function fetchUser (email) {
  try {
    var requestBody = {
      query: `
        query getUserByEmail ($input: GetUserInput!) {
          getUser(input: $input) {
            error
            data {
              id
              name
              email
            }
          }
        }
      `,
      variables: {
        input: {
          email: email
        }
      }
    }

    const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/graphql/project/alpha`, requestBody);
    const { getUser } = response.data.data;
    return getUser;
  } catch (e) {
    return {
      error: e
    }
  }
}

const UserCardAuto = ({ email, ...props }) => {
  const [ user, setUser ] = useState(null);

  useEffect(() => {
    fetchUser(email)
      .then((userResponse) => {
        setUser(userResponse);
      })
      .catch((e) => {
        setUser({
          error: e.error
        })
      })
  }, [email]);

  if (!user) {
    return (<span>Carregando...</span>);
  }

  if (user.error) {
    return (<span>Erro: {user.error}</span>);
  }

  return (<UserCard2 name={user.data.name} email={user.data.email} {...props} />)
}

export default UserCardAuto;