import React from 'react';
import { withRouter } from 'react-router-dom';
import { Box, Title } from '../../components/Whitebox';

const OAuthPage = (props) => {
  return (
    <Box>
      <Title>OAUTH v2.0</Title>
      <span>No description</span>
      <br/>
      <br/>
    </Box>
  )
}

export default withRouter(OAuthPage);