import 'react-app-polyfill/ie11';
import 'core-js/stable';
import 'core-js/es/typed-array/uint32-array';
import 'core-js/es/array/find';
import 'core-js/es/object/assign';
import 'core-js/es/object/entries';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import './css/index.css';

//document.domain = process.env.REACT_APP_DOMAIN;

ReactDOM.render(<App />, document.getElementById('root'));