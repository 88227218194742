import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: rgba(0,0,0,0.025);
  border-radius: 4px;
  padding: 14px 7px;
  border: 1px solid #ccc;
  margin: 8px 0px;
  overflow-wrap: anywhere;

  & img {
    min-width: 40px;
    min-height: 40px;
    /*border: 2px solid #ccc;*/
    border-radius: 50%;
  }

  & .user-avatar-info {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
  }

  & .user-avatar-signout {
    font-size: 14px;
    color: #0084EE;
    outline: none;
    border: none;
    background: none;
    padding: 0px;
    margin: 0px;
    cursor: pointer;
  }

  &.color-success {
    color: #009000;
    border-color: #009000;
    background-color: #e5f4e5;

    & img {
      /*border-color: #009000;*/
    }
  }
`

const User = ({ className, name, email, ...props }) => {
  return (
    <Container className={clsx(className)} {...props}>
      <img
        src={`https://ui-avatars.com/api/?name=${name}&font-size=0.33&rounded=true`}
        alt={`Avatar do usuário "${name}"`}
        title={`Avatar do usuário "${name}"`}
        width='40'
        height='40'
      />
      <div className="user-avatar-info">
        <span>{name || '???'}</span>
        <small>{email || '???'}</small>
      </div>
    </Container>
  )
}

export default User;