import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Box, Title } from '../../components/Whitebox';
import SignInForm from '../../components/SignInForm';

const DEFAULT_URL = '/';

const getDefaultEmail = (location) => {
  var query = new URLSearchParams(location.search);

  if (query.has('email')) {
    return query.get('email');
  }

  return '';
}

class SignInPage extends React.PureComponent {

  renderLoading = () => {
    return (
      <Title>Carregando...</Title>
    );
  }

  getRedirectUri = () => {
    var redirectUrl = DEFAULT_URL;
    var queryStrings = new URLSearchParams(this.props.location.search);

    if (queryStrings.has('redirectUri')) {
      redirectUrl = queryStrings.get('redirectUri');
    }

    return redirectUrl;
  }

  renderSigned = () => {
    var redirectUri = this.getRedirectUri();

    try {
      return (
        <>
          <Title>Redirecionando...</Title>
          <span>Você será redirecionado dentro de alguns segundos</span>
          <br/><br/>

          <small>caso você não tenha sido redirecionado automáticamente <a href={redirectUri}>clique aqui</a></small>
        </>
      );
    } finally {
      setTimeout(() => {
        window.location.href = redirectUri;
      }, 3000);
    }
  }

  renderNonSigned = () => {
    var redirectUri = this.getRedirectUri();

    return (
      <SignInForm
        redirectUri={redirectUri}
        displaySignUp={false}
        notifySessionChanges={true}
        username={getDefaultEmail(this.props.location)} 
      />
    )
  }

  renderContent = () => {
    const { user } = this.props;

    if (user.loading) {
      return this.renderLoading();
    }

    if (user.connected === false) {
      return this.renderNonSigned();
    }

    return this.renderSigned();
  }

  render () {
    return (
      <Box>
        { this.renderContent() }
      </Box>
    )
  }
}

export default connect((state) => {
  return {
    user: state.user
  }
})(withRouter(SignInPage));