import React from 'react';
import Amplify from 'aws-amplify';
import validator from 'validator';

import { Box, Body, Footer, Title } from '../../components/Whitebox';
import TextField from '../../components/TextField';
import Button from '../../components/Button';
import VerifyAccountForm from '../../components/VerifyUserForm';
import { withRouter } from 'react-router-dom';

const DEFAULT_URL = '/';

/**
 * Validation: not empty
 * @param {String} value
 */
const validateNotEmpty = (value) => {
  if (value && value.length > 0) {
    return null;
  }

  if (value.length === 0) {
    return 'Por favor, preencha este campo'
  }
}

/**
 * Validation: email address
 * @param {String} value
 */
const validateEmailAddress = (value) => {
  if (!validator.isEmail(value)) {
    return 'Informe um endereço de e-mail válido';
  }
}

/**
 * Validation: minimum password length
 * @param {String} value
 * @param {Number} min
 */
const validatePasswordLength = (value, min) => {
  if (value.length < min) {
    return `Por favor, use ${min} ou mais caracteres`;
  }

  return null;
}

/**
 * Validation: confirm password
 * @param {String} a
 * @param {String} b
 */
const validateConfirmPassword = (a, b) => {
  if (a.length === 0) {
    return 'Por favor, Preencha este campo';
  }

  if (a !== b) {
    return 'As senhas não coincidem';
  }

  return null;
}

const getDefaultEmail = (location) => {
  var query = new URLSearchParams(location.search);

  if (query.has('email')) {
    return query.get('email');
  }

  return '';
}

class SignUpPage extends React.Component {

  constructor (props) {
    super(props);

    this.state = {
      data: {
        name: '',
        email: getDefaultEmail(props.location),
        password: ''
      },
      loading: false,
      created: false,
      confirmed: false,
      validationErrors: {}
    }
  }

  /**
   * Handle user sign up
   * @param {Event} e
   */
  handleSignUp = () => {
    this.setState({
      loading: true,
      created: false,
      confirmed: false
    });

    var signUpParams = {
      username: this.state.data.email.toLowerCase(),
      password: this.state.data.password,
      attributes: {
        name: this.state.data.name,
        email: this.state.data.email.toLowerCase()
      }
    };

    Amplify.Auth.signUp(signUpParams)
      .then((response) => {
        this.setState({
          loading: false,
          created: true,
          confirmed: false
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          created: false,
          confirmed: false
        });
      });
  }

  handleFinish = () => {
    Amplify.Auth.signIn(this.state.data.email, this.state.data.password)
      .then(() => {
        var redirectUri = this.getRedirectUri();
        window.location.href = redirectUri;
        // TODO: Force login
      })
      .catch((e) => {
        window.alert('Falha ao logar na sua conta récem criada.');
        console.log(e);
      });
  }

  getRedirectUri = () => {
    var redirectUrl = DEFAULT_URL;
    var queryStrings = new URLSearchParams(this.props.location.search);

    if (queryStrings.has('redirectUri')) {
      redirectUrl = queryStrings.get('redirectUri');
    }

    return redirectUrl;
  }

  /**
   * Handle user sign in click
   * @param {Event} e
   */
  handleSignIn = () => {
    var redirectUri = this.getRedirectUri();
    var queryStrings = '';
    
    if (redirectUri !== '/') {
      queryStrings = `?redirectUri=${encodeURIComponent(redirectUri)}`;
    }

    window.location.href = `/#/sign-in${queryStrings}`;
  }

  /**
   * On field change
   * @param {String} field
   * @returns {Function} handler
   */
  handleFieldChange = (field) => {
    return (e) => {
      var newData = { ...this.state.data };
      newData[field] = e.target.value;
      this.setState({ data: newData });
    }
  }

  /**
   * On field blur
   * @param {String} field
   * @returns {Function} handler
   */
  handleFieldBlur = (field) => {
    return (e) => {
      var errors = { ...this.state.validationErrors };
      var value = this.state.data[field] || '';

      if (field === 'name') {
        errors[field] = validateNotEmpty(value);
      }

      if (field === 'email') {
        errors[field] = validateNotEmpty(value) || validateEmailAddress(value);
      }

      if (field === 'password') {
        errors[field] = validateNotEmpty(value) || validatePasswordLength(value, 6);
      }

      if (field === 'passwordConfirm') {
        errors[field] = validateNotEmpty(value) || validateConfirmPassword(value, this.state.data.password);
      }

      this.setState({
        validationErrors: errors
      });
    }
  }

  /**
   * Get validation error for field
   * @param {String} field
   * @returns {String|undefined}
   */
  getValidationErrorOf = (field) => {
    return this.state.validationErrors[field] || undefined;
  }

  renderForm () {
    return (
      <>
        <Title>Criar uma conta no Industrial Photo Management</Title>
        <Body>
          <TextField
            label="Nome"
            value={this.state.data.name}
            error={this.getValidationErrorOf('name')}
            onChange={this.handleFieldChange('name')}
            onBlur={this.handleFieldBlur('name')}
          />

          <TextField
            label="Email"
            type="email"
            value={this.state.data.email}
            disabled={true}
          />

          <TextField
            label="Senha"
            type="password"
            value={this.state.data.password}
            error={this.getValidationErrorOf('password')}
            onChange={this.handleFieldChange('password')}
            onBlur={this.handleFieldBlur('password')}
          />

          <TextField
            label="Confirmar"
            type="password"
            value={this.state.data.passwordConfirm}
            error={this.getValidationErrorOf('passwordConfirm')}
            onChange={this.handleFieldChange('passwordConfirm')}
            onBlur={this.handleFieldBlur('passwordConfirm')}
          />

          <Footer style={{padding: '4px'}}>
            <Button color="primary" type="button" onClick={this.handleSignUp}>
              { this.state.loading ? 'Cadastrando...' : 'Cadastrar' }
            </Button>

            {/*<Button color="secondary" type="button" onClick={this.handleSignIn}>
              Faça login em vez disso
            </Button>*/}
          </Footer>
        </Body>
      </>
    )
  }

  renderVerifyAccount = () => {
    return (
      <VerifyAccountForm
        username={this.state.data.email}
        onSuccess={this.handleFinish}
      />
    );
  }

  renderContent = () => {
    if (!this.state.created) {
      return this.renderForm();
    }

    if (!this.state.confirmed) {
      return this.renderVerifyAccount();
    }

    return (<span>Algo deu errado</span>);
  }

  render () {
    return (
      <Box>{this.renderContent()}</Box>
    )
  }
}

export default withRouter(SignUpPage);