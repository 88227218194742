import React, { memo, useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Body, Footer, Title } from '../../components/Whitebox';
import TextField from '../../components/TextField';
import Button from '../../components/Button';
//import UserCard from '../../components/UserCard';
//import UserCard2 from '../../components/UserCard2';
import UserCardAuto from '../../components/UserCardAuto';
import { Auth } from 'aws-amplify';

/**
 * Load invite data
 * @param {String} inviteId 
 * @param {Object} api 
 */
async function fetchInvite (inviteId) {
  try {
    var requestBody = {
      query: `
        query getInvite ($input: GetInviteInput!) {
          getInvite(input: $input) {
            error
            data {
              id
              email
              isRegistred
              isTaken
              project {
                id
                name
              }
            }
          }
        }
      `,
      variables: {
        input: {
          id: inviteId
        }
      }
    }

    const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/graphql/project/alpha`, requestBody);
    const { getInvite } = response.data.data;

    /*
    if (getInvite.error) {
      throw new Error(getInvite.error);
    }
    */

    return getInvite;
  } catch (e) {
    return {
      error: e
    }
  }
}

async function signIn (params) {
  var requestBody = {
    query: `
      mutation signIn ($input: SignInInput!) {
        signIn(input: $input) {
          error
          data {
            accessToken
            refreshToken
          }
        }
      }
    `,
    variables: {
      input: {
        email: params.email,
        password: params.password
      }
    }
  }

  const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/graphql/project/alpha`, requestBody);
  const { signIn } = response.data.data;
  return signIn
}

async function signUp (params) {
  var requestBody = {
    query: `
      mutation signUp ($input: SignUpInput!) {
        signUp(input: $input) {
          error
          userId
        }
      }
    `,
    variables: {
      input: {
        name: params.name,
        email: params.email,
        password: params.password
      }
    }
  }

  const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/graphql/project/alpha`, requestBody);
  const { signUp } = response.data.data;
  return signUp
}

async function completeInvite (inviteId, code, options) {
  try {
    var requestBody = {
      query: `
        mutation completeInvite ($input: CompleteInviteInput!) {
          completeInvite(input: $input) {
            error
          }
        }      
      `,
      variables: {
        input: {
          id: inviteId,
          code: code
        }
      }
    };

    var requestOptions = {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    };

    console.log('complete', options);
    const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/graphql/_`, requestBody, requestOptions);
    const { completeInvite } = response.data.data;
    return completeInvite

  } catch (e) {
    console.log(e);
    return {
      error: e
    }
  }
}

const InviteSignIn = ({ id, code, invite, onComplete, ...props }) => {
  const [ sending, setIsSending ] = useState(false);
  const [ password, setPassword ] = useState('');
  const [ error, setError ] = useState(null);

  const handleChange = (fieldName, e) => {
    setPassword(e.target.value);
  }

  const handleBlur = (fieldName, e) => {

  }

  const handleSubmit = () => {
    var form = {
      //invite: id,
      email: invite.email,
      password: password
    }

    console.log('Sign in', form);    
    setIsSending(true);

    signIn(form)
      .then((signInResponse) => {
        setIsSending(false);
        console.log('sign in response', signInResponse);

        if (signInResponse.error) {
          setError(signInResponse.error);
          return;
        }

        const completeInviteParams = { accessToken: signInResponse.data.accessToken };
        return completeInvite(id, code, completeInviteParams);
      })      
      .then((completeInviteResponse) => {
        if (completeInviteResponse.error) {
          throw new Error(completeInviteResponse.error);
        }

        return completeInviteResponse
      })
      .then(() => {
        console.log('Amplify sign in');
        return Auth.signIn({
          username: form.email,
          password: form.password
        })
      })
      .then(() => {
        onComplete({ event: 'sign_in', data: form });
      })
      .catch((signInError) => {
        console.log('sign in error', signInError);
        setError(signInError);
      })
  }

  return (
    <Box>
    <Title>Você recebeu um convite</Title>
    <span>Você foi convidado para participar do projeto '<strong>{invite.project.name || 'Sem nome'}</strong>'</span><span> </span>
    <br />
    <br />
    <span>
      <small>
        Você já possui uma conta na nossa plataforma, para continuar  por favor digite sua senha no campo abaixo e <strong>clique em login para aceitar o convite:</strong>
      </small>
    </span>
    <br />
    <br />
    <Body>
      <div style={{margin: '0px 4px'}}>
        <UserCardAuto email={invite.email} />
        {/*<a>Recuperar senha</a>*/}
      </div>

      <TextField
        label="Senha"
        type="password"
        value={password}
        onChange={handleChange.bind(this, 'password')}
        onBlur={handleBlur.bind(this, 'password')}
        error={null}
      />

      { error && (<span>Error: {error}</span>) }

      <Footer style={{padding: '4px'}}>
        <Button color="primary" type="button" onClick={handleSubmit}>
          { sending ? 'Entrando...' : 'Login' }
        </Button>
      </Footer>
    </Body>
  </Box>
  )
}

const InviteSignUp = ({ id, code, invite, onComplete, ...props }) => {
  const [ sending, setIsSending ] = useState(false);
  const [ error, setError ] = useState(null);
  const [ name, setName ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ passwordConfirm, setPasswordConfirm ] = useState('');
  
  const handleChange = (fieldName, e) => {
    if (fieldName === 'name') {
      setName(e.target.value);
    }

    if (fieldName === 'password') {
      setPassword(e.target.value);
    }

    if (fieldName === 'passwordConfirm') {
      setPasswordConfirm(e.target.value);
    }
  }

  const handleBlur = (fieldName, e) => {

  }

  const handleSubmit = () => {
    var form = {
      //invite: id,
      name: name,
      email: invite.email,
      password: password
    }

    console.log('Sign up', form);
    setIsSending(true);

    signUp(form)
      .then((signUpResponse) => {
        setIsSending(false);
        console.log('sign up response', signUpResponse);

        if (signUpResponse.error) {
          setError(signUpResponse.error);
          return;
        }

        return signUpResponse;
      })
      .then(() => {
        const signInForm = {
          email: invite.email,
          password: password
        }

        return signIn(signInForm);
      })
      .then((signInResponse) => {
        return completeInvite(id, code, { accessToken: signInResponse.data.accessToken });
      })
      .then((completeInviteResponse) => {
        if (completeInviteResponse.error) {
          throw new Error(completeInviteResponse.error);
        }

        return completeInviteResponse;
      })
      .then(() => {
        console.log('Amplify sign in');
        return Auth.signIn({
          username: form.email,
          password: form.password
        })
      })
      .then(() => {
        onComplete({ event: 'sign_up', data: form });
      })
      .catch((signUpError) => {
        setError(signUpError);
      })
  }

  return (
    <Box>
      <Title>Você recebeu um convite</Title>
      <span>Você foi convidado para participar do projeto '<strong>{invite.project.name || 'Sem nome'}</strong>'</span><span> </span>
      <br />
      <br />
      <span>
        <small>
          Você ainda não possui uma conta na nossa plataforma, para continuar é necessário se <strong>cadastrar através do formulário abaixo:</strong>
        </small>
      </span>
      <br />
      <br />
      <Body>        
        <TextField
          label="Email"
          type="email"
          value={invite.email}
          disabled={true}
        />

        <TextField
          label="Nome"
          value={name}
          error={null}
          onChange={handleChange.bind(this, 'name')}
          onBlur={handleBlur.bind(this, 'name')}
        />

        <TextField
          label="Senha"
          type="password"
          value={password}
          onChange={handleChange.bind(this, 'password')}
          onBlur={handleBlur.bind(this, 'password')}
          error={null}
        />

        <TextField
          label="Confirmar"
          type="password"
          value={passwordConfirm}
          error={null}
          onChange={handleChange.bind(this, 'passwordConfirm')}
          onBlur={handleBlur.bind(this, 'passwordConfirm')}
        />

        { error && (<span>Error: {error}</span>) }

        <Footer style={{padding: '4px'}}>
          <Button color="primary" type="button" onClick={handleSubmit}>
            { sending ? 'Cadastrando...' : 'Cadastrar' }
          </Button>
        </Footer>
      </Body>
    </Box>
  )
}

const InviteCongrats = ({ id, code, invite, ...props }) => {

  const handleOpen = () => {
    window.location.href = `${process.env.REACT_APP_APP_ENDPOINT}/#/`;
  }

  return (
    <Box>
        <Title>Convite aceito!</Title>

        <UserCardAuto email={invite.email} className="color-success" />
        <span>Seu convite foi aceito, agora você está participando do projeto "<strong>{invite.project.name}</strong>"</span>
        <br/>
        <br/>

        <Button onClick={handleOpen}>Abrir projeto</Button>
    </Box>
  )
}

const InviteRoot = ({ id, code, ...props }) => {
  const [ invite, setInvite ] = useState({ touched: false, fetching: false, error: null, data: null });
  const [ isFinished, setIsFinished ] = useState(false);

  useEffect(() => {
    setInvite({
      touched: true,
      fetching: true,
      error: null,
      data: null
    });

    fetchInvite(id)
      .then((response) => {
        console.log('response', response);
        setInvite({
          touched: true,
          fetching: false,
          ...response
        })
      })
      .catch((error) => {
        console.log('error', error);
      })
  }, [id]);

  const handleComplete = () => {
    console.log('On complete!');
    setIsFinished(true);
  }

  if (!invite || !invite.touched || invite.fetching) {
    return (
      <Box>
        <span>Carregando</span>
      </Box>
    )
  }

  if (invite.error) {
    return (
      <Box>
        <span>Error</span>
        <small>{invite.error}</small>
      </Box>
    )
  }

  if (isFinished) {
    return (<InviteCongrats id={id} code={code} invite={invite.data} />)
  }

  if (invite.data.isRegistred) {
    return (<InviteSignIn id={id} code={code} invite={invite.data} onComplete={handleComplete} />)
  }

  return (<InviteSignUp id={id} code={code} invite={invite.data} onComplete={handleComplete} />)
}

const InvitePage = memo(({ match, location }) => {
  const { id } = match.params;
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code');

  if (!code) {
    return (<p>Código não definido</p>);
  }

  return (<InviteRoot id={id} code={code} />)
});

export default InvitePage;